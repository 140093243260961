/**
 * @description 공통 코드 정의
 * - 섹션: section
 * - 토픽: topic
 * - 이슈: issue
 * - 연재: series
 * - 코너: corner
 * - 프로그램: program
 */
export const commonCode = {
	SECTION: 'section',
	TOPIC: 'topic',
	ISSUE: 'issue',
	SERIES: 'series',
	CORNER: 'corner',
	PROGRAM: 'program',
} as const

/**
 * @description API 응답 코드 정의
- 00 ("Searched successfully.")
- 94 ("N/A", "N/A (WCMS에서 설정한 메시지가 노출) 서비스 점검 중 페이지에 노출될 문구, WCMS에서 설정한 문구가 삽입")
- 95 ("customer.login.required", "로그인이 필요한 서비스입니다.")
- 96 ("common.proc.failed.invalidUrl", "유효하지 않은 URL 입니다.")
- 97 ("common.proc.failed.invalidMethod", "유효하지 않은 Method 입니다.")
- 98 ("N/A", "(VO의 Annotation으로 설정한 메시지가 노출)")
- 99 ("common.proc.failed", "처리를 실패하였습니다.")
 */
export const APIResultCode = {
	SUCCESS: '00',
	NO_DATA: '10',
	MAINTENANCE: '94',
	LOGIN_REQUIRED: '95',
	INVALID_URL: '96',
	INVALID_METHOD: '97',
	ANNOTATION_NA: '98',
	FAILED: '99',
} as const
