import { HttpClient } from './fetch'

export const convertFetchURL = (url: string) => {
	if (url.includes('/restapi')) {
		return url.replace('/restapi', '')
	}
	return url
}

export const getBaseURL = () => {
	return process.env.NEXT_PUBLIC_APP_API
}

export const request = new HttpClient({
	baseURL: getBaseURL(),
	beforeRequest(url, { setUrl }) {
		if (url.includes('/restapi')) {
			setUrl(url.replace('/restapi', ''))
		}
	},
})
