import { NextResponse } from 'next/server'

import { APIResultCode } from 'src/shared/code'
import { convertFetchURL, getBaseURL } from '.'

// APIRequestParams 타입을 정의하여 API 호출에 필요한 매개변수 명확화
type APIRequestParams = {
	path: string
	method?: 'GET' | 'POST'
	params?: URLSearchParams
	cookieHeader?: string
	body?: BodyInit
}

// 공통 API 호출 함수
export const fetchAPI = async ({
	path,
	method = 'GET',
	params,
	cookieHeader,
	body,
}: APIRequestParams) => {
	const url = `${getBaseURL()}${path}${params ? `?${params.toString()}` : ''}`
	const headers = new Headers({
		Accept: 'application/json',
		Origin: process.env.NEXT_PUBLIC_SITE_URL,
		...(cookieHeader && { Cookie: cookieHeader }),
	})

	// if (!(body instanceof FormData)) {
	// 	headers.append('Content-Type', 'application/json')
	// }

	try {
		const response = await fetch(convertFetchURL(url), {
			method,
			headers,
			body: body instanceof FormData ? body : JSON.stringify(body),
			// next: { revalidate: 60 },
			cache: 'no-cache',
			credentials: 'include',
		})

		// 502 Bad Gateway 에러 발생 시 빈 응답 반환
		if (response.status === 502) {
			return {
				resultCode: '00',
				data: null,
			}
		}

		// 로그인이 필요한 경우
		if (response.status === 401) {
			throw new Error(JSON.stringify({ resultCode: APIResultCode.LOGIN_REQUIRED }))
		}

		const result = await response.json()

		if (
			!response.ok ||
			(result.resultCode !== APIResultCode.SUCCESS &&
				result.resultCode !== APIResultCode.NO_DATA &&
				result.resultCode !== APIResultCode.MAINTENANCE &&
				result.resultCode !== '60') // 기자 응원 중복 에러 코드
		) {
			// 그 외의 코드들은 에러로 처리하지 않고 결과만 반환
			return {
				resultCode: '00',
				data: null,
			}
		}

		return result
	} catch (error) {
		console.log(`Error occured in ${path}`, error)
		throw error
	}
}

/**
 *
 * @description (토픽/이슈) pv 통계 호출을 위한 쿠키 설정
 * @param url URL 객체
 * @param response NextResponse 객체
 */
export function handlePackageRequest(url: URL, response: NextResponse) {
	const areaDiv = url.searchParams.get('areaDiv')
	const areaIdx = url.searchParams.get('areaIdx')

	if (areaDiv) {
		response.cookies.set('areaDiv', areaDiv, {
			sameSite: 'lax',
			httpOnly: true,
			secure: true,
		})
	}

	if (areaIdx) {
		response.cookies.set('areaIdx', areaIdx, {
			sameSite: 'lax',
			httpOnly: true,
			secure: true,
		})
	}
}
